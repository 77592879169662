import Vue from 'vue'
import App from './App.vue'
import Airtable from 'airtable'

Vue.config.productionTip = false

Vue.prototype.$airtable = new Airtable({ apiKey: process.env.VUE_APP_AIRTABLE_API_KEY }).base(process.env.VUE_APP_AIRTABLE_APP)

new Vue({
  render: h => h(App),
}).$mount('#app')
