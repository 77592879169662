<template>
  <div id="app" v-bind:class="{ debug: debug }">
    <div id="map"></div>
    <div class="fixed">
      <div class="locations">
        <div class="buttons are-small mb-2">
          <button id="cambridge" class="button is-rounded">Cambridge</button>
          <button id="london" class="button is-rounded">London</button>
        </div>
      </div>
      <div v-if="selectedPropertyID">
        <div class="card" v-for="content in selectedPropertyContent" v-bind:key="content.id">
          <button @click="selectedPropertyID = null" class="close button is-rounded is-dark is-small">
            <span class="icon is-small">
              <i class="fas fa-times"></i>
            </span>
          </button>
          <div v-if="content.photos">
            <carousel :autoplay="true" :perPage="1">
              <slide v-for="photo in content.carousel.slice(0,5)" v-bind:key="photo.id">
                <div v-if="(photo.thumbnails.large.width / photo.thumbnails.large.height) > 1">
                  <figure class="image is-5by3">
                    <img v-bind:src="photo.thumbnails.large.url" alt="">
                  </figure>
                </div>
                <div v-else-if="(photo.thumbnails.large.width / photo.thumbnails.large.height) == 1">
                  <figure class="image is-1by1">
                    <img v-bind:src="photo.thumbnails.large.url" alt="">
                  </figure>
                </div>
                <div v-else>
                  <figure class="image is-5by3 image-vertical">
                    <img v-bind:src="photo.thumbnails.large.url" alt="">
                  </figure>
                </div>
              </slide>
            </carousel>
          </div>
          <!-- <div v-show="content.photos">
            <div class="card-image" v-for="(photo, index) in content.photos" v-once v-bind:key="photo.id">
              <div v-if="index == 0">
                <div v-if="(photo.thumbnails.large.width / photo.thumbnails.large.height) > 1">
                  <figure class="image is-5by3">
                    <img v-bind:src="photo.thumbnails.large.url" alt="">
                  </figure>
                </div>
                <div v-else-if="(photo.thumbnails.large.width / photo.thumbnails.large.height) == 1">
                  <figure class="image is-1by1">
                    <img v-bind:src="photo.thumbnails.large.url" alt="">
                  </figure>
                </div>
                <div v-else>
                  <figure class="image is-5by3 image-vertical">
                    <img v-bind:src="photo.thumbnails.large.url" alt="">
                  </figure>
                </div>
              </div>
            </div>
          </div> -->
          <div class="card-content">
            <div class="content">
              <p v-show="debug"><small>{{ selectedPropertyID }}</small></p>
              <h2 class="mt-0 is-size-5">{{ content.title }}</h2>
              <p v-show="content.rent">{{ content.rent }}<small>/month</small></p>
              <!-- <p v-html="truncate(content.description,140)" v-show="content.description"></p> -->
              <ul v-show="content.photos" class="thumbnails">
                <li v-for="photo in content.photos" v-bind:key="photo.id">
                  <div v-if="photo.thumbnails">
                    <button class="plain" @click="modalActive = !modalActive"><img :src="photo.thumbnails.small.url"></button>
                  </div>
                </li>
              </ul>
              <div class="columns is-multiline">
                <div v-show="content.beds" class="column is-6"><small>Beds</small><br>{{ content.beds }}</div>
                <div v-show="content.availableFrom" class="column is-6"><small>Baths</small><br>{{ content.baths }}</div>
                <div v-show="content.furnishing" class="column is-6"><small>Furnishing</small><br>{{ content.furnishing }}</div>
                <div v-show="content.billsIncluded" class="column is-6"><small>Bills included</small><br>{{ content.billsIncluded }}</div>
                <!-- <div v-show="content.availableFrom" class="column is-6"><small>Available from</small><br>{{ content.availableFrom }}</div> -->
              </div>
              <p><button @click="modalActive = !modalActive" class="button is-primary is-fullwidth">Find out more</button></p>
            </div>
          </div>
        </div>
      
        <div class="modal" :class="{ 'is-active': modalActive }">
          <div class="modal-background" @click="modalActive = !modalActive"></div>
          <div class="modal-content p-4">
            <div class="card" v-for="content in selectedPropertyContent" v-bind:key="content.id">
              <div v-show="content.photos">
                <div class="card-image" v-for="(photo, index) in content.carousel" v-bind:key="photo.id">
                  <div v-if="index == 0">
                    <div v-if="(photo.thumbnails.large.width / photo.thumbnails.large.height) > 1">
                      <figure class="image is-5by3">
                        <img v-bind:src="photo.thumbnails.large.url" alt="">
                      </figure>
                    </div>
                    <div v-else-if="(photo.thumbnails.large.width / photo.thumbnails.large.height) == 1">
                      <figure class="image is-1by1">
                        <img v-bind:src="photo.thumbnails.large.url" alt="">
                      </figure>
                    </div>
                    <div v-else>
                      <figure class="image is-5by3 image-vertical">
                        <img v-bind:src="photo.thumbnails.large.url" alt="">
                      </figure>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-content">
                <div class="content">
                  <p v-show="debug"><small>{{ selectedPropertyID }}</small></p>
                  <h2 class="mt-0 is-size-5">{{ content.title }}</h2>
                  <p v-show="content.rent">{{ content.rent }}<small>/month</small></p>
                  <div v-show="content.availableFrom" class="mb-2"><small>Available from</small><br>{{ content.availableFrom }}</div>
                  <p><a href="https://www.redwoodco-living.co.uk/contact" target="_parent" class="button is-primary is-fullwidth">Enquire now</a></p>
                  <div v-if="content.description">
                    <div v-html="markdown(content.description)" class="pb-4"></div>
                  </div>
                  <ul v-show="content.photos" class="photos">
                    <li v-for="photo in content.photos" v-bind:key="photo.id">
                      <div v-if="photo.thumbnails">
                        <img :src="photo.thumbnails.large.url">
                      </div>
                    </li>
                  </ul>
                  <div class="columns is-multiline">
                    <div v-show="content.beds" class="column is-6"><small>Beds</small><br>{{ content.beds }}</div>
                    <div v-show="content.baths" class="column is-6"><small>Baths</small><br>{{ content.baths }}</div>
                    <div v-show="content.furnishing" class="column is-6"><small>Furnishing</small><br>{{ content.furnishing }}</div>
                    <div v-show="content.billsIncluded" class="column is-6"><small>Bills included</small><br>{{ content.billsIncluded }}</div>
                    <!-- <div v-show="content.availableFrom" class="column is-6"><small>Available from</small><br>{{ content.availableFrom }}</div> -->
                  </div>
                  <div v-show="content.meta" class="columns is-multiline">
                    <div v-for="item in content.meta" v-bind:key="item.label" class="column is-6"><small>{{ item.label }}</small><br>{{ item.value }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button @click="modalActive = !modalActive" class="modal-close is-large" aria-label="close"></button>
        </div>
        
      </div>
    </div>
    <div class="data" v-if="debug">
      <div v-if="isLoading">
        Loading...
      </div>
      <div v-else>
        {{ listings }}
        <!-- <ul>
          <li v-for="(property, index) in properties" v-bind:key="property.id">
            {{ index }} {{ property }}
          </li>
        </ul> -->
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import { marked } from 'marked';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js'
import redMarker from './assets/img/red.png';
mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;

export default {
  name: 'App',
  data() {
    return {
      isLoading: true,
      properties: [],
      map: null,
      debug: false,
      geoJson: [],
      listings: [],
      selectedPropertyContent: [],
      selectedPropertyID: null,
      modalActive: false,
      cambridge: {
        lng: 0.12181699999996454,
        lat:  52.20533799999998
      },
      london: {
        lng: -0.11809199999993325,
        lat:  51.53
      }
    }
  },
  components: {
    Carousel,
    Slide
  },
  methods: {
    truncate(value, size) {
      if (!value) return '';
      value = value.toString();

      if (value.length <= size) {
        return value;
      }
      return value.substr(0, size) + '...';
    },
    markdown(text) {
     return marked(text);
    },
    loadMap() { 
      this.map = new mapboxgl.Map({
        cooperativeGestures: true,
        container: 'map',
        style: 'mapbox://styles/danryland/cky352evb538o14ru72eftfcm',
        center: [this.cambridge.lng,this.cambridge.lat], // Cambridge
        zoom: 12,
      });

    },
    getProperties() {
      const self = this;
      //var index = 1;
      this.$airtable('Property').select().all().then(records => {

        //console.log('Got records')

        records.forEach(function(record) {

          //console.log('Each record')

          if(record.get('Latitude')) {

            //console.log('One record')

            var carousel = record.get('Photos');
            //carousel.length = 5;

            var property = {
              id: record.id,
              title: record.get('Title'),
              carousel: carousel,
              rent: record.get('Rent (£/month)'),
              description: record.get('Description'),
              photos: record.get('Photos'),
              beds: record.get('Beds'),
              baths: record.get('Baths'),
              furnishing: record.get('Furnishing'),
              billsIncluded: record.get('Bills included'),
              availableFrom: record.get('Available from'),
              latitude: record.get('Latitude'),
              longitude: record.get('Longitude'),

              meta: [
                {
                  label: 'Baths',
                  value: record.get('Baths')
                },
                {
                  label: 'Maximum tenants',
                  value: record.get('Maximum tenants')
                },
                {
                  label: 'Deposit (£)',
                  value: record.get('Deposit (£)')
                },
                {
                  label: 'Minimum tenancy',
                  value: record.get('Minimum tenancy')
                },
                {
                  label: 'Student friendly',
                  value: record.get('Student friendly')
                },
                {
                  label: 'Families allowed',
                  value: record.get('Families allowed')
                },
                {
                  label: 'Pets allowed',
                  value: record.get('Pets allowed')
                },
                {
                  label: 'Smokers allowed',
                  value: record.get('Smokers allowed')
                },
                {
                  label: 'DSS income accepted',
                  value: record.get('DSS income accepted')
                },
                // {
                //   label: 'Available',
                //   value: record.get('Available')
                // },
                {
                  label: 'Location',
                  value: record.get('Location')
                },
                {
                  label: 'Garden',
                  value: record.get('Garden')
                },
                {
                  label: 'Parking',
                  value: record.get('Parking')
                },
                {
                  label: 'Fireplace',
                  value: record.get('Fireplace')
                },
                {
                  label: 'EPC',
                  value: record.get('EPC')
                },
              ],
              //all: record.fields
            }
            self.properties.push(property);

            //console.log(index++, record.id)

          }
        });
      }).then(function(){
        //console.log(self.properties)
        self.applyMarkers()
        //console.log('Done')
        self.isLoading = false;
      }).catch(err => {
          console.log('Database error: ', err)
      })
    },

    applyMarkers() {

      //console.log('Apply marker')

      const self = this;
      const length = self.properties.length; 

      //console.log(self.properties)
      //console.log(self.properties.length)

      for ( var i = 0; i < length; i++ ) {
        const long = parseFloat(self.properties[i].longitude)
        const lat = parseFloat(self.properties[i].latitude)

        const listing = {
          type: "Feature",
          properties: {
            id: self.properties[i].id,
            'marker-color': '#3bb2d0',
            'marker-size': 'large',
            'marker-symbol': 'rocket',
          },
          geometry: {
            type: "Point",
            coordinates: [long, lat]
          }
        };

        self.geoJson.push(listing);

        //self.coords.push([long, lat])

      }

      self.listings = {
        type: 'geojson',
        data: {
            type: "FeatureCollection",
            features: self.geoJson
        },
        cluster: true,
        clusterMaxZoom: 22,
        clusterRadius: 16,
      };

      //console.log(self.listings);        

      self.map.addSource('listings', self.listings);

      //console.log(self.map)
 
      self.map.loadImage(redMarker, (error, image) => {
        if (error) throw error;

        self.map.addImage('red-marker', image, { 'sdf': true });

        self.map.addLayer({
          id: 'points',
          source: 'listings',
          type: 'symbol',
          filter: ['!', ['has', 'point_count']],
          layout: {
            'icon-image': 'red-marker',
            'icon-size': 0.75,
            'icon-allow-overlap': true,
          },
          paint: {
            'icon-color': '#e6332c'
          }
        });

        self.map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: 'listings',
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#B22822',
                    5,
                    '#801C18',
                    10,
                    '#661714'
                ],
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    16,
                    5,
                    24,
                    10,
                    32
                ]
            }
        });

        self.map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: 'listings',
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['Arial Unicode MS Bold'],
                'text-size': 16,
            },
            paint: {
              "text-color": "white",
            }
        });

      });

      self.map.on('click', 'clusters', (e) => {
          const features = this.map.queryRenderedFeatures(e.point, {
              layers: ['clusters']
          });
          const clusterId = features[0].properties.cluster_id;
          self.map.getSource('listings').getClusterExpansionZoom(
              clusterId,
              (err, zoom) => {
                  if (err) return;

                  self.map.easeTo({
                      center: features[0].geometry.coordinates,
                      zoom: zoom
                  });
              }
          );
      });

      self.map.on('click', 'points', (e) => {
        this.activeListing(e);
      });

      self.map.on('mouseenter', 'points', (e) => {
        this.activeListing(e);
      });

      self.map.on('touchstart', 'points', (e) => {
        this.activeListing(e);
      });

      self.map.on('mouseenter', 'points', () => {
          self.map.getCanvas().style.cursor = 'pointer';
      });
      self.map.on('mouseleave', 'points', () => {
          self.map.getCanvas().style.cursor = '';
      });
      
      self.map.on('mouseenter', 'clusters', () => {
          self.map.getCanvas().style.cursor = 'pointer';
      });
      self.map.on('mouseleave', 'clusters', () => {
          self.map.getCanvas().style.cursor = '';
      });

    },
    activeListing(e) {
      const self = this;
      //console.log("Active: ",e.features[0])
      self.selectedPropertyID = e.features[0].properties.id;
      //console.log(self.selectedPropertyID);
      //console.log(e.features[0].geometry.coordinates);
      self.selectedPropertyContent = self.properties.filter(obj => {
        return obj.id === self.selectedPropertyID
      })
      // console.log(self.selectedPropertyContent);
    }
  },
  created() {
    if(this.$airtable) {
      this.getProperties()
    }
  },
  mounted() {
    this.loadMap()
    
    const {lng, lat} = this.map.getCenter();
    if(lng == this.cambridge.lng && lat == this.cambridge.lat) {
      document.getElementById('cambridge').classList.add("is-primary");
      document.getElementById('london').classList.remove("is-primary");
    }

    this.map.on('moveend', () => {
      const {lng, lat} = this.map.getCenter();
      if(lng == this.cambridge.lng && lat == this.cambridge.lat) {
        document.getElementById('cambridge').classList.add("is-primary");
        document.getElementById('london').classList.remove("is-primary");
        //console.log('Cambridge')
      }
      if(lng == this.london.lng && lat == this.london.lat) {
        document.getElementById('cambridge').classList.remove("is-primary");
        document.getElementById('london').classList.add("is-primary");
        //console.log('London')
      }
    });

    document.getElementById('cambridge').addEventListener('click', () => {
      this.map.flyTo({
        center: [this.cambridge.lng,this.cambridge.lat],
        essential: true,
        zoom: 12,
      });
    });
    document.getElementById('london').addEventListener('click', () => {
      this.map.flyTo({
        center: [this.london.lng,this.london.lat],
        essential: true,
        zoom: 12,
      });
    });

  }
}
</script>

<style lang="scss">

$gutter: 8px;
$primary: #e6332c;
$dark: #2e2e2e;

@import "~bulma/bulma";

body {
  background: $dark;
}
li {
  margin-bottom: $gutter*2;
}
* {
  box-sizing: border-box;
}
.debug #map {
  width: 80%;
  left: 20%;
}
.debug .data {
  width: 20%;
  display: block;
}

.plain {
  border: none;
  padding: 0px;
  margin: 0px;
  background: none;
  &:hover {
    cursor: pointer;
  }
}
#map {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.data {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100%;
  width: 100%;
  z-index: 1;
  padding: 80px 20px 20px 20px;
  color: white;
  overflow-y: scroll;
  border: 1px solid black;
  display: none;
  background: $dark;
}
.popup {
  padding: 5px;
  * {
    padding: 0px !important;
    margin: 0px !important;
  }
}
.fixed {
  position: absolute;
  $indent: $gutter*3;
  top: $indent;
  left: 0px;
  padding: 0px 0px 0px $indent;
  width: 100%;
  z-index: 10;
  @media screen and (min-width: 426px) {
    width: 360px
  }
}

.locations {
  position: fixed;
  left: 50%;
  margin-left: -88px;
}

.mapboxgl-marker:hover {
  cursor: pointer;
}
.image-vertical {
  overflow: hidden;
  position: relative;
  border-radius: 4px 4px 0px 0px;
  img {
    position: absolute !important;
    height: auto !important;
    top: -50% !important;
    //margin-bottom: -213px !important;
  }
}
.thumbnails {
  margin: 0px 0px $gutter*2 0px !important;
  padding: 0px !important;
  overflow-x: scroll;
  height: 36px;
  white-space: nowrap;
  li {
    list-style: none;
    display: inline-block;
    padding: 0px $gutter 0px 0px !important;
    margin: 0px !important;
    img {
      max-height: 36px;
    }
  }
}

.photos {
  margin: 0px 0px $gutter*2 0px !important;
  padding: 0px !important;
  li {
    list-style: none;
    display: block;
    padding: 0px !important;
    margin: 0px 0px $gutter*3 0px !important;
    div {
      text-align: center;
    }
    img {
      
    }
  }
}

.card {
  width: 100% !important;
  margin-right: 20px;
  overflow: hidden;
  margin-top: 46px;
  .close {
    position: absolute;
    top: $gutter;
    right: $gutter;
    z-index: 1;
    height: 36px;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
}

.modal-background {
  background-color: rgba($dark,0.9);
}

.VueCarousel-pagination {
  position: absolute;
  bottom: 0px;
  .VueCarousel-dot-container {
    margin-top: 0px !important;
    height: 30px !important;
    overflow: hidden;
    button {
      margin-top: 0px !important
    }
    .VueCarousel-dot:focus {
      outline: none !important;
    }
    .VueCarousel-dot--active {
      background-color: $primary !important;
    }
  }
}

</style>
